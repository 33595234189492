<section class="ts-schedule" id="registration">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="section-title">Registration</h2>
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
    <div class="row">
      <div class="col-lg-12">
        <div class="tab-content schedule-tabs">
          <div role="tabpanel" class="tab-pane active" id="date3">
            <!--schedule-listing end -->

            <div class="row alert alert-success text-center">
              Thank you for registrarion, please check your inbox, spam or junk
              for further information.
            </div>
            <!--schedule-listing end -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- container end-->
  <div class="speaker-shap">
    <img class="shap2" src="images/shap/home_schedule_memphis1.png" alt="" />
    <img class="shap1" src="images/shap/home_schedule_memphis2.png" alt="" />
  </div>
</section>
