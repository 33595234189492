// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiRoot: 'https://app.medicalevents.tv/api',
  apiKey: '50b59cd75bed76f46522c350587e331f6d9339e5c1a7493c',
  // imageLink: 'http://cm_ups.local/',
  imageLink: 'https://medicalevents.tv/cm_ups',
  selectedEvent: '142',
  certificate_link: 'https://app.medicalevents.tv',
  con_name: '3rd APAA  Advances in Psychiatry Across Ages Conference ',
  con_date: '9th-11th February, 2024',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
