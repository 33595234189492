<section id="speakers" class="">
  <!--Container-->
  <div class="container spacer-double-sm">
    <!--Row-->
    <div class="row">
      <div class="col">
        <div class="mb-5 pb-5 text-center">
          <span class="icon-title icon-bg-dark rounded-circle mb-3">
            <img
              class="icon-title-inner"
              src="assets/svg/speaker-icon-2.svg"
              alt=""
            />
          </span>
          <h2 class="h1">Main Speakers</h2>
          <p class="w-md-60 mx-auto mb-0 lead">
            We are bringing you the perfect combination of content and
            experience to ignite your heart equip your mind and spark your
            skill.
          </p>
        </div>
      </div>
    </div>
    <!--End row-->
    <!--Row-->
    <div class="row justify-content-center">
      <div class="col-xl-10 col-lg-11">
        <div class="row">
          <div class="col-sm-6 col-md-4 text-center mb-5">
            <div class="d-flex flex-column align-items-center">
              <img
                src="assets/img/p1.jpg"
                class="avatar-lg mb-4 rounded-circle"
                alt=""
              />
              <h5 class="mb-0">Jeremy Spivak</h5>
              <span class="mb-3 text-secondary">CEO, Founder</span>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 text-center mb-5">
            <div class="d-flex flex-column align-items-center">
              <img
                src="assets/img/p2.jpg"
                class="avatar-lg mb-4 rounded-circle"
                alt=""
              />
              <h5 class="mb-0">David Taylor</h5>
              <span class="mb-3 text-secondary">User Research</span>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 text-center mb-5">
            <div class="d-flex flex-column align-items-center">
              <img
                src="assets/img/p3.jpg"
                class="avatar-lg mb-4 rounded-circle"
                alt=""
              />
              <h5 class="mb-0">Renee Mu</h5>
              <span class="mb-3 text-secondary">Content Strategy</span>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 text-center mb-5 mb-lg-0">
            <div class="d-flex flex-column align-items-center">
              <img
                src="assets/img/p4.jpg"
                class="avatar-lg mb-4 rounded-circle"
                alt=""
              />
              <h5 class="mb-0">Melissa Wagner</h5>
              <span class="mb-3 text-secondary">UX Metrics</span>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 text-center mb-5 mb-lg-0">
            <div class="d-flex flex-column align-items-center">
              <img
                src="assets/img/p3.jpg"
                class="avatar-lg mb-4 rounded-circle"
                alt=""
              />
              <h5 class="mb-0">Renee Mu</h5>
              <span class="mb-3 text-secondary">Visual Design</span>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 text-center">
            <div class="d-flex flex-column align-items-center">
              <img
                src="assets/img/p1.jpg"
                class="avatar-lg mb-4 rounded-circle"
                alt=""
              />
              <h5 class="mb-0">Jeremy Spivak</h5>
              <span class="mb-3 text-secondary">User Research</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End row-->
    <!--Row-->
    <div class="row justify-content-center mt-5 pt-5">
      <div class="col-auto">
        <div class="alert shadow-lg mb-0 text-center">
          <p class="mb-0">
            Interested in attending the conference ?
            <a
              [routerLink]="['./', 'register']"
              class="hover-arrow text-blue scroll"
              >Registration <span class="fa fa-arrow-right"></span
            ></a>
          </p>
        </div>
      </div>
    </div>

    <!--End row-->
  </div>
  <!--End container-->
</section>
