<section id="about" class="o-hidden">
  <!--Container-->
  <div class="container spacer-double-sm">
    <!--Row-->
    <div
      class="
        row
        justify-content-between
        position-relative
        align-items-center
        z-index-2
      "
    >
      <div class="col-md-6 mb-5 mb-md-0">
        <h1 class="mb-4">Organized by</h1>
        <p class="lead">
          ACPN is a premier medical facility focusing on providing you with the
          highest quality primary and specialized medical care and health
          education services Psychiatry department services spans over a range
          of Adult, Child and Adolescents Psychiatry. In addition to specialized
          clinics such as Women Mental Health Clinic, Eating Disorders Clinic,
          Mood Disorders Clinic, Disability Determination/Fit to Work Clinic,
          Aviation Psychiatry and Addiction Psychiatry. Neurology department
          includes services for Adult, Child and Adolescents Neurology, In
          addition to specialized clinics such as MS clinic, Headache clinic and
          Sleep Disorders clinic. Psychology department offer child services
          range from psychometric assessment of learning difficulties and
          developmental delay to therapeutic services and offer adult services
          cover a wide range of interventions from counseling for personal and
          emotional problems to working with severe and complex mental health
          problems.
        </p>
        <p class="lead mb-0"></p>
      </div>
      <div class="col-md-5">
        <img
          class="w-75"
          src="assets/img2/american-center.jpg"
          alt="presedent message"
        />
      </div>
    </div>
  </div>
  <!--End container-->
</section>
<div class="curved-decoration">
  <svg
    version="1.1"
    class="bg-green-svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 2560 221"
    enable-background="new 0 0 2560 221"
    xml:space="preserve"
  >
    <polygon points="2560,221 0,221 2560,0 "></polygon>
  </svg>
</div>
